.cookie-statement ol {
  counter-reset: item;
}

.cookie-statement li {
  position: relative;
}

.cookie-statement ol > li {
  padding-left: 32px;
  margin-bottom: 24px;
}

.cookie-statement ul > li {
  margin-bottom: 24px;
}

.cookie-statement ul {
  list-style-type: disc !important;
  margin-left: 16px !important;
}

.cookie-statement ul > li::marker {
  padding-left: 32px;
  position: absolute;
  left: 0;
  top: 0;
}

.cookie-statement ol > li::before {
  content: counters(item, '.') '.';
  counter-increment: item;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 2.625rem;
  font-size: 1.875rem;
  color: #434345;
  font-family: NotoSans, 'Trebuchet MS', Helvetica, sans-serif;
  font-weight: 700;
}

.cookie-statement li li::before {
  font-weight: 300;
  line-height: 1.5;
  font-size: medium;
}

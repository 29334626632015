.enter-country {
  cursor: url('flag.png'), pointer;
}

.legend-container {
  background: linear-gradient(360deg, #434345 0%, rgb(196 196 196 / 0%) 100%);
  opacity: 0.2;
  height: 52px;
}

.strips {
  background: repeating-linear-gradient(
    -45deg,
    #126261,
    #26baac 3px,
    #126261 3px
  );
}

pattern {
  background: #26baac;
}

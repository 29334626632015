@font-face {
  font-family: NotoSans;
  src: url('fonts/NotoSans/NotoSans-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: NotoSans;
  src: url('fonts/NotoSans/NotoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: NotoSans;
  src: url('fonts/NotoSans/NotoSans-SemiBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: NotoSans;
  src: url('fonts/NotoSans/NotoSans-Bold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: NotoSans;
  src: url('fonts/NotoSans/NotoSans-Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: NotoSans;
  src: url('fonts/NotoSans/NotoSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: NotoSans;
  src: url('fonts/NotoSans/NotoSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: NotoSans;
  src: url('fonts/NotoSans/NotoSans-BoldItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

html,
body,
#root {
  min-height: 100vh;
}

body {
  font-family: NotoSans, 'Trebuchet MS', Helvetica, sans-serif;
  font-weight: 300;
  -webkit-overflow-scrolling: auto;
}

/* Added to prevent the "flex child cannot collapse properly" bug: https://css-tricks.com/flexbox-truncated-text/ */
.flex > * {
  min-width: 0;
}

/* Cookie Control Styles (see CookieControl.load() in index.html */
/* stylelint-disable selector-class-pattern */
#ccc-content {
  background: #265ba7;
}

#ccc-icon.ccc-icon--dark {
  fill: #183968 !important;
}

.ccc-panel {
  padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

#ccc .ccc-content--dark h2 {
  font: 700 22px/30px NotoSans, 'Trebuchet MS', Helvetica, sans-serif;
}

#ccc .ccc-content--dark h3 {
  font: 700 16px/24px NotoSans, 'Trebuchet MS', Helvetica, sans-serif;
}

#ccc .ccc-content--dark p {
  font: 300 16px/24px NotoSans, 'Trebuchet MS', Helvetica, sans-serif;
}

#ccc #ccc-recommended-settings {
  border-radius: 24px;
  text-align: center;
  border-width: 1px;
  margin-right: 16px;
}

#ccc #ccc-reject-settings {
  border-radius: 24px;
  border: 1px solid #fff;
}

#ccc .ccc-notify-button span {
  font: 500 16px/24px NotoSans, 'Trebuchet MS', Helvetica, sans-serif;
  color: #fff;
}

#ccc .ccc-content--dark hr {
  background-color: #d9dbdd;
  opacity: 1;
}

#ccc .checkbox-toggle--slider.checkbox-toggle--dark {
  background-color: #183968 !important;
  border-color: #183968 !important;
}

#ccc .checkbox-toggle--slider.checkbox-toggle--dark .checkbox-toggle-label {
  text-transform: uppercase;
}

#ccc .checkbox-toggle--slider.checkbox-toggle--dark .checkbox-toggle-toggle {
  background: #fff;
}

#ccc .ccc-module--slideout .ccc-info {
  display: none;
}

#ccc .ccc-svg-element {
  display: none !important;
}

#ccc .ccc-content--dark p a {
  font-weight: 500;
  text-decoration: underline;
}

#ccc-close,
#ccc-notify-dismiss,
#ccc-notify-reject {
  display: none !important;
}

.scrollbar-always-visible {
  --scrollbar-foreground: #5378ac;
  --scrollbar-foreground-hover: #5fa8ca;
  --scrollbar-background: #f7f4ed;

  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
  overflow-y: scroll;
  scrollbar-width: thin;
}

.scrollbar-always-visible::-webkit-scrollbar {
  width: 10px;
  background-color: var(--scrollbar-background);
}

.scrollbar-always-visible::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: var(--scrollbar-background);
}

.scrollbar-always-visible::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-foreground);
  border-radius: 20px;
}

.scrollbar-always-visible::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-foreground-hover);
}
/* stylelint-enable selector-class-pattern */

.td-box-shadow::after {
  content: '';
  position: absolute;
  display: block;
  background: linear-gradient(90deg, transparent, rgb(50 50 50 / 5%));
  width: 20px;
  top: 0;
  bottom: 0;
  left: -20px;
  border-right: 1px solid rgb(217 219 221 / var(--tw-border-opacity));
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  box-shadow: 0 2px 4px rgb(67 67 69 / 20%);
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext-large {
  width: auto;
  padding: 8px 4px;
}

.tooltip .tooltiptext-large::after {
  left: 20%;
}

:root {
  --solid-black: rgb(0 0 0 / 0%);
  --translucent-black: rgb(0 0 0 / 50%);
}

.open-country-anime {
  animation-name: countrymodalopen;
  animation-duration: 0.7s;
}

.close-country-anime {
  background: transparent;
  visibility: hidden;
  animation-name: countrymodalclose;
  animation-duration: 0.7s;
}

.fade-in-background {
  background: var(--translucent-black);
  animation-name: fadein;
  animation-duration: 0.7s;
}

.fade-out-background {
  background: var(--solid-black);
  visibility: hidden;
  animation-name: fadeout;
  animation-duration: 0.7s;
}

@keyframes countrymodalopen {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
    transition: all 0.7s ease;
  }
}

@keyframes countrymodalclose {
  0% {
    transform: translateX(0%);
    visibility: visible;
  }

  100% {
    transform: translateX(100%);
    transition: all 0.7s ease;
  }
}

@keyframes fadein {
  0% {
    background: var(--solid-black);
  }

  100% {
    background: var(--translucent-black);
    transition: all 0.7s ease;
  }
}

@keyframes fadeout {
  0% {
    background: var(--translucent-black);
    visibility: visible;
  }

  100% {
    background: var(--solid-black);
    transition: all 0.7s ease;
  }
}

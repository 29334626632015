.dot-flashing {
  position: relative;
  margin-left: 10px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0.25s;
  bottom: 7px;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
}

.dot-flashing::before {
  left: -7px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 7px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0.5s;
}

@keyframes dotFlashing {
  0% {
    background-color: #1a81c4;
  }
  50%,
  100% {
    background-color: #d9d9d9;
  }
}

.fullscreen-modal {
  min-width: 100vw;
  min-height: 100vh;
}

.open-modal-anime {
  animation-name: modalopen;
  animation-duration: 0.8s;
}

.modal-hidden-anime {
  background: transparent;
  visibility: hidden;
  opacity: 1;
  animation-name: modalclose;
  animation-duration: 0.7s;
}

@keyframes modalopen {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    transform: scale(1.05);
    opacity: 0.8;
    transition: all 0.8s ease;
  }

  100% {
    transform: scale(1);
    transition: all 0.8s ease;
  }
}

@keyframes modalclose {
  0% {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
  }

  100% {
    opacity: 0;
    transform: scale(0);
    transition: all 0.8s ease;
  }
}

:root {
  --base-blue: #2aa7df;
  --base-grey: #b9bcc0;
}

.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.radio-container.with-border {
  padding: 16px;
  padding-left: 40px;
  border-radius: 0.25rem;
  border: 1px solid var(--base-grey);
}

.radio-container.with-border:hover {
  border: 1px solid var(--base-blue);
}

.radio-container input {
  position: absolute;
  opacity: 0;
}

.radio-checkmark {
  position: absolute;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid var(--base-grey);
}

.radio-container.with-border .radio-checkmark {
  left: 10px;
}

.radio-container:hover input ~ .radio-checkmark {
  border: 1px solid var(--base-blue);
}

.radio-container .radio-checkmark::after {
  top: 5px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  content: '';
  position: absolute;
  display: none;
  background-color: var(--base-blue);
}

.radio-container input:checked ~ .radio-checkmark::after {
  display: block;
}

.item {
  min-width: 100%;
  width: max-content;
  max-width: 600px;
}

.item .item {
  margin-left: 20px;
}

.item ul li:first-of-type {
  margin-top: 10px;
}

/* this is only neccesairy because our tailwind version is way too old */
.items {
  transform: translateY(-1px);
}
